<template>
  <div class="test-add">
    <br />
    <el-form
      ref="elForm"
      :model="rowData"
      :rules="rules"
      size="medium"
      label-width="160px"
      label-suffix="："
    >
      <div class="step1">
        <el-form-item :label="$t('test.candidate')" prop="candidateName">
          <el-input
            :placeholder="$t('common.pleaseEnter') + $t('test.candidate')"
            v-model="rowData.candidateName"
            style="width: 70%"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('test.candidateArea')" prop="candidatePhonePrefix">
          <el-select
            v-model="rowData.candidatePhonePrefix"
            :placeholder="$t('common.pleaseEnter') + $t('test.candidateArea')"
            style="width: 70%"
            @change="switchArea"
          >
            <el-option :label="$t('test.china')" value="86"></el-option>
            <el-option label="U.S.A" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('test.candidatePhone')" prop="candidatePhone">
          <el-input
            :placeholder="$t('common.pleaseEnter') + $t('test.candidatePhone')"
            v-model="rowData.candidatePhone"
            style="width: 70%"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('test.candidateEmail')" prop="candidateEmail">
          <el-input
            :placeholder="$t('common.pleaseEnter') + $t('test.candidateEmail')"
            v-model="rowData.candidateEmail"
            style="width: 70%"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('test.candidateGender')" prop="candidateSex">
          <el-select
            v-model="rowData.candidateSex"
            :placeholder="$t('common.pleaseSelect')"
            style="width: 70%"
          >
            <el-option :label="$t('test.male')" value="男"></el-option>
            <el-option :label="$t('test.female')" value="女"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('test.examStartTime')" prop="examTime">
          <el-date-picker
            :popper-class="this.rowData.candidatePhonePrefix=='1'?'utc-style':''"
            @change="timeToUTC"
            v-model="rowData.examTime"
            type="datetime"
            :placeholder="$t('test.selectTime')"
            value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 70%"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
        <!-- <el-form-item :label="$t('test.styleType')" prop="style">
          <el-radio-group v-model="rowData.style">
            <el-radio :label="1">{{ $t('test.defaultStyle') }}</el-radio>
            <el-radio :label="2">{{ $t('test.newStyle') }}</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item :label="$t('test.ifrecordSreen')" prop="screenRecording">
          <el-radio-group v-model="rowData.screenRecording">
            <el-radio :label="1">{{ $t('test.yes') }}</el-radio>
            <el-radio :label="2">{{ $t('test.no') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('test.notifyMethod')" prop="notificationMode">
          <el-radio-group v-model="rowData.notificationMode">
            <el-radio :label="1">{{$t('test.Email')}}</el-radio>
            <el-radio :label="2">{{$t('test.messages')}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('test.isVerification')" prop="isVerification">
          <el-radio-group v-model="rowData.isVerification">
            <el-radio :label="true">{{$t('test.yes')}}</el-radio>
            <el-radio :label="false">{{$t('test.no')}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- <el-form-item :label="$t('test.notifyMethod')" prop="notificationMode">
          <el-checkbox v-model="method.message">{{
            $t('test.messages')
          }}</el-checkbox>
          <el-checkbox v-model="method.email" checked @click.native.prevent>{{
            $t('test.Email')
          }}</el-checkbox>
        </el-form-item>-->
        <!--<el-form-item label="是否开启WebIde：">-->
        <!--<el-radio-group v-model="rowData.enableIde">-->
        <!--<el-radio :label="1">是</el-radio>-->
        <!--<el-radio :label="0">否</el-radio>-->
        <!--</el-radio-group>-->
        <!--</el-form-item>-->
      </div>
    </el-form>
    <div class="boontbtn">
      <el-button
        type="primary"
        size="small"
        style="margin: 0 30px"
        @click="submitAdd()"
        v-loading="loading"
        :disabled="loading"
      >{{ $t('test.saveAndPublish') }}</el-button>
      <el-button type="info" size="small" @click="$emit('clear')">
        {{
        $t('common.cancel')
        }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { post, del, get, putUrl } from "@/api/http";
import setTime from "@/utils/setime.js";
export default {
  name: "test-add",
  props: {
    rowData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      method: {
        email: true,
        message: false,
      },
      pickerOptions: {
        cellClassName: (date) => {
          // 时区选美国才会添加utc类名
          // console.log(date.toString().slice(0,15));
          if (this.rowData.candidatePhonePrefix == "1") {
            let utctime = this.$moment(setTime(new Date()))
              .utcOffset(-5)
              .format("YYYY-MM-DD HH:mm:ss");
            // console.log(new Date(utctime).toString().slice(0,15),'utctime');
            if (
              date.toString().slice(0, 15) ==
              new Date(utctime).toString().slice(0, 15)
            ) {
              return "utctoday";
            }
          }
        },
      },
      rules: {
        candidateName: [
          {
            required: true,
            message: this.$t("common.pleaseEnter") + this.$t("test.candidate"),
            trigger: "blur",
          },
        ],
        candidatePhonePrefix: [
          {
            required: true,
            message:
              this.$t("common.pleaseEnter") + this.$t("test.candidateArea"),
            trigger: "blur",
          },
        ],
        candidatePhone: [
          {
            required: true,
            message:
              this.$t("common.pleaseEnter") + this.$t("test.candidatePhone"),
            trigger: "blur",
          },
        ],
        candidateEmail: [
          {
            required: true,
            message:
              this.$t("common.pleaseEnter") + this.$t("test.candidateEmail"),
            trigger: "blur",
          },
        ],
        examTime: [
          {
            required: true,
            message:
              this.$t("common.pleaseSelect") + this.$t("test.examStartTime"),
            trigger: "blur",
          },
        ],
        candidateSex: [
          {
            required: true,
            message:
              this.$t("common.pleaseSelect") + this.$t("test.candidateGender"),
            trigger: "blur",
          },
        ],
      },
      paperList: [],
      questionList: [],
      questionLength: 0,
      questionScore: 0,
      questionTime: 0,
      step: 0,
      explain:
        "1.测试有效时间2021-10-01 08:00:00~2021-10-03 08:00:00\n" +
        "2.答题的电脑需要配置摄像头，并把摄像头对准身体，否则不能参加测试\n" +
        "3.测试过程中，全程录制屏幕以及摄像头录像，如有弹出对话框，请点击同意",
      choiceSeeDialog: false,
      shortSeeDialog: false,
      isProgram: false,
      enableIde: false,
      loading: false,
    };
  },
  methods: {
    getPaper() {
      post(
        "/paper/list",
        {
          page: 1,
          limit: 1000,
        },
        true
      ).then((res) => {
        if (res.code == 1) {
          this.paperList = res.data.data;
        } else {
          this.$message.error(res.data);
        }
      });
    },
    paperChange() {
      console.log(this.rowData.paperId);
      post(
        "/paper-question/list",
        {
          paperId: this.rowData.paperId,
        },
        true
      ).then((res) => {
        if (res.code == 1) {
          this.questionList = res.data;
          let questionScore = 0,
            questionTime = 0,
            isProgram = false;
          res.data.map((item) => {
            questionScore += Number(item.score);
            questionTime += Number(item.answerTime);
            if (item.type == "4") {
              isProgram = true;
            }
          });
          this.isProgram = isProgram;
          this.questionLength = res.data.length;
          this.questionScore = questionScore;
          this.questionTime = questionTime;
          if (isProgram) {
            this.$alert("题目中包含编程题，需开启WebIde", "提示", {
              confirmButtonText: "确定",
              callback: (action) => {
                this.enableIde = isProgram;
              },
            });
          }
        } else {
          this.$message.error(res.data);
        }
      });
    },
    //下一步
    nextStep() {
      this.step += 1;
    },
    previousStep() {
      this.step -= 1;
    },
    submitAdd() {
      this.$refs["elForm"].validate((valid) => {
        if (!valid) {
          this.$message.error(this.$t("common.pleaseComplete"));
          return false;
        } else {
          // this.method.message
          //   ? (this.rowData.notificationMode = 2)
          //   : (this.rowData.notificationMode = 1)
          this.loading = true;
          this.$emit("add", { enableIde: "1", ...this.rowData });
        }
      });
    },
    stepClidk(step) {
      this.step = step;
    },
    clearHand() {
      this.choiceSeeDialog = false;
      this.shortSeeDialog = false;
    },
    // 国家为美国时,点击此刻按钮切换UTC-5时区
    timeToUTC(time) {
      // console.log(time);
      // console.log(setTime(new Date()));
      if(this.rowData.candidatePhonePrefix == "1"){
        if (time == setTime(new Date())) {
          let utctime = this.$moment(time)
            .utcOffset(-5)
            .format("YYYY-MM-DD HH:mm:ss");
          this.$set(this.rowData, "examTime", utctime);
        }
      }
    },
    // 切换时区，给dom添加/删除类名，否则不能重新渲染视图
    switchArea(data) {
      let nodes = document.getElementsByClassName("el-date-picker");
      if (nodes.length !== 0) {
        if (data == 1) {
          nodes[0].classList.add("utc-style");
        } else {
          nodes[0].classList.remove("utc-style");
        }
      }
    },
    openSee(item) {
      if (item.type == "1") {
        get("/question-store/find-id-choice/" + item.id).then((res) => {
          if (res.code == 1) {
            if (res.data.labelId) {
              res.data.labelId = res.data.labelId.split(",");
            }
            this.choiceSeeDialog = true;
            this.$nextTick(() => {
              this.$refs["seeRef"].setData(res.data);
            });
          } else {
            this.$message.error(res.data);
          }
        });
      } else {
        get("/question-store/find-id-essay/" + item.id).then((res) => {
          if (res.code == 1) {
            if (res.data.labelId) {
              res.data.labelId = res.data.labelId.split(",");
            }
            this.shortSeeDialog = true;
            this.$nextTick(() => {
              this.$refs["seeRef"].setData(res.data);
            });
          } else {
            this.$message.error(res.data);
          }
        });
      }
    },
  },
  mounted() {
    this.getPaper();
    this.rowData.notificationMode == 2
      ? (this.method.message = true)
      : (this.method.message = false);
  },
};
</script>

<style scoped>
.step-box {
  position: relative;
  width: 100%;
  height: 60px;
}
.step-box .step1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  cursor: pointer;
}

.step-box .step1 img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
}
.step-box .step-select .step-img {
  /*display: none;*/
}
.step-box .step1 p {
  position: absolute;
  left: 0;
  top: 0;
  line-height: 50px;
  text-align: center;
  width: 110%;
  font-size: 16px;
}
.step-box .step-select p {
  color: #ffffff;
}
.step-box .step2 {
  position: absolute;
  right: 0;
  top: 0;
  width: 50%;
  cursor: pointer;
}
.step-box .step2 img {
  position: absolute;
  left: -6%;
  top: 0;
  width: 110%;
  height: 50px;
}
.step-box .step2 p {
  position: absolute;
  left: -6%;
  top: 0;
  line-height: 50px;
  text-align: center;
  width: 110%;
  font-size: 16px;
}
.step-box .step-select p {
  color: #ffffff;
}
.table-title {
  background-color: #f3f5f7;
  padding: 0px 20px;
  font-size: 14px;
  color: #778ca2;
  font-weight: bold;
}

.table-content li {
  padding: 0px 20px;
  border-bottom: 1px solid #f3f5f7;
}
.table-content li:hover {
  background-color: #f8f8fd;
}
.table-content li span {
  margin-left: 10px;
  font-size: 14px;
  color: #fff;
  padding: 0 15px;
  line-height: 22px;
}

.warning {
  background-color: #ffb61e;
}

.success {
  background-color: #00af9b;
}

.danger {
  background-color: #ff2121;
}
::v-deep .utc-style .el-date-table td.utctoday span {
  color: #339;
  font-weight: 700;
}
::v-deep .utc-style .el-date-table td.today span {
  color: #333;
  font-weight: normal;
}
</style>
<!-- 修改utc时间当前日期高亮 -->
<style>
.utc-style .el-date-table td.utctoday span, .utc-style .el-date-table td.utctoday.today span{
  color: #339;
  font-weight: 700;
}
.utc-style .el-date-table td.today span {
  color: #333;
  font-weight: normal;
}
.utc-style .el-date-table td.current:not(.disabled) span {
  color: #fff;
  background-color: #339;
}
</style>