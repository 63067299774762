<template>
  <div class="detail">
    <div class="detail-header">
      <div class="title-box">
        <div class="title">
          <h3>{{ clickItem.title }}({{ clickItem.score }}分)</h3>
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="detail-content">
      <!-- 试题内容 -->
      <mavon-editor v-if="clickItem.content" :value="clickItem.content" defaultOpen="preview" :boxShadow="false"
        style="z-index: 1; max-height: 50vh !important; overflow-x: hidden" :editable="false" :subfield="false"
        :toolbarsFlag="false" class="makeEditor" :language="editorLanguage"
        :class="{ 'left-content': clickItem.type == '4' }">
      </mavon-editor>
      <!-- 选择题 -->
      <div class="answer-box" v-if="clickItem.type == '1'">
        <div v-if="answerData">
          <!-- <el-radio-group v-model="answerData.answer">
            <el-row class="list-option" v-if="clickItem.optionA">
              <el-col :span="20">
                <el-radio label="A" :disabled="haveReadonly">{{
                clickItem.optionA
                }}</el-radio>
              </el-col>
            </el-row>
            <el-row class="list-option" v-if="clickItem.optionB">
              <el-col :span="20">
                <el-radio label="B" :disabled="haveReadonly">{{
                clickItem.optionB
                }}</el-radio>
              </el-col>
            </el-row>
            <el-row class="list-option" v-if="clickItem.optionC">
              <el-col :span="20">
                <el-radio label="C" :disabled="haveReadonly">{{
                clickItem.optionC
                }}</el-radio>
              </el-col>
            </el-row>
            <el-row class="list-option" v-if="clickItem.optionD">
              <el-col :span="20">
                <el-radio label="D" :disabled="haveReadonly">{{
                clickItem.optionD
                }}</el-radio>
              </el-col>
            </el-row>
          </el-radio-group> -->
          <el-checkbox-group v-model="chioceAnswer">
            <el-row class="list-option" v-if="clickItem.optionA">
              <el-col :span="20">
                <el-checkbox label="A" style="pointer-events: none;">{{
                clickItem.optionA
                }}</el-checkbox>
              </el-col>
            </el-row>
            <el-row class="list-option" v-if="clickItem.optionB">
              <el-col :span="20">
                <el-checkbox label="B" style="pointer-events: none;">{{
                clickItem.optionB
                }}</el-checkbox>
              </el-col>
            </el-row>
            <el-row class="list-option" v-if="clickItem.optionC">
              <el-col :span="20">
                <el-checkbox label="C" style="pointer-events: none;">{{
                clickItem.optionC
                }}</el-checkbox>
              </el-col>
            </el-row>
            <el-row class="list-option" v-if="clickItem.optionD">
              <el-col :span="20">
                <el-checkbox label="D" style="pointer-events: none;">{{
                clickItem.optionD
                }}</el-checkbox>
              </el-col>
            </el-row>
          </el-checkbox-group>
        </div>
        <div v-if="!answerData">
          <el-radio-group>
            <el-row class="list-option" v-if="clickItem.optionA">
              <el-col :span="20">
                <el-radio label="A" :disabled="haveReadonly">{{
                clickItem.optionA
                }}</el-radio>
              </el-col>
            </el-row>
            <el-row class="list-option" v-if="clickItem.optionB">
              <el-col :span="20">
                <el-radio label="B" :disabled="haveReadonly">{{
                clickItem.optionB
                }}</el-radio>
              </el-col>
            </el-row>
            <el-row class="list-option" v-if="clickItem.optionC">
              <el-col :span="20">
                <el-radio label="C" :disabled="haveReadonly">{{
                clickItem.optionC
                }}</el-radio>
              </el-col>
            </el-row>
            <el-row class="list-option" v-if="clickItem.optionD">
              <el-col :span="20">
                <el-radio label="D" :disabled="haveReadonly">{{
                clickItem.optionD
                }}</el-radio>
              </el-col>
            </el-row>
          </el-radio-group>
          <div class="wrong-tips">
            {{ $t('test.notAnswer') }}
          </div>
        </div>
        <br />
        <div class="answer-bg">
          <div class="">
            <span>{{ $t('test.score') }}：</span>
            <span class="red">{{ scoreValue }}</span>
            <svg-icon icon-class="correct" class="correct-icon" style="color: #fb3636" @click="$emit('correctOper')" />
          </div>
          <div class="" style="padding-top: 15px">
            <span>{{ $t('test.standardAns') }}：</span>
            <span>{{ clickItem.answers }}</span>
          </div>
        </div>
      </div>
      <!-- 编程题 -->
      <template v-else-if="clickItem.type == '4'">
        <div style="
            width: 51%;
            float: left;
            margin: 28px 0 20px 0;
            overflow: hidden;
            height: 510px;
            position: relative;
          ">
          <aceEditor v-model="runCodeAnswers" @init="editorInit" lang="java" theme="monokai" width="100%" 
          :style="isFold?'height:452px':'height:310'" :options="aceOptions"></aceEditor>
          <div class="run-code-box">
            <div class="arrow-down-icon" @click="runFlag = true">
              <i class="el-icon-arrow-up"></i>
            </div>
            <el-row type="flex" justify="space-around">
              <el-col :span="6">
                <div class="run-code-results" @click="runFlag = true">
                  {{ $t('test.autumn') }}
                </div>
              </el-col>
              <el-col :span="6"> </el-col>
              <el-col :span="6">
                <div class="run-code-results run-code-perform"></div>
                <el-button type="danger" class="run-code-perform" icon="el-icon-caret-right" @click="runCode">{{
                $t('test.executeCode') }}</el-button>
              </el-col>
            </el-row>
          </div>
          <div class="run-code-dialog" :style="isFold?'height:60px':'height:200px'">
            <el-row type="flex" class="run-boder-bottom" justify="space-around">
              <el-col :span="6" class="dis-flex">
                <div class="run-code-results black">
                  {{ $t('test.autumn') }}
                </div>
                <el-button type="text" icon="el-icon-caret-bottom" size="mini" class="red" v-if="isFold" @click="foldUp"></el-button>
                <el-button type="text" icon="el-icon-caret-top" size="mini" class="red" v-else @click="foldUp"></el-button>
              </el-col>
              <el-col :span="6"></el-col>
              <el-col :span="6"></el-col>
            </el-row>
            <div class="run-code-content">
              <span :class="{ red: runRedError }">{{ runningResult }}</span>
            </div>
          </div>
        </div>
        <!-- <div style="margin: 20px 0">
          <span class="correct">{{ $t('test.correctAnswer') }}：</span>
          <svg-icon
            icon-class="correct"
            class="correct-icon"
            @click="$emit('correctOper')"
          /><span style="color: #f00"
            >{{ scoreValue }}{{ $t('test.point') }}</span
          >
        </div> -->
        <div class="answer-bg" style="margin: 15px 0; overflow: hidden">
          <div class="">
            <span>{{ $t('test.score') }}：</span>
            <span class="red">{{ scoreValue }}</span>
            <svg-icon icon-class="correct" class="correct-icon" style="color: #fb3636" @click="$emit('correctOper')" />
          </div>
          <!-- <div class="" style="padding-top: 15px">
            <span>{{ $t('test.standardAns') }}：</span>
          </div> -->
        </div>
      </template>
      <!-- 简答题 -->
      <div v-else>
        <div v-if="answerData && answerData.answer">
          <mavon-editor :toolbars="toolbars" v-model="answerData.answer" class="mavonEditor" :editable="false"
            :language="editorLanguage" />
        </div>
        <div v-if="!answerData" class="wrong-tips">
          {{ $t('test.notAnswer') }}
        </div>
        <!-- <p style="margin: 20px 0">
          <span class="correct">{{ $t('test.correctAnswer') }}：</span>
          <svg-icon
            icon-class="correct"
            class="correct-icon"
            @click="$emit('correctOper')"
          /><span style="color: #f00"
            >{{ scoreValue }}{{ $t('test.point') }}</span
          >
        </p> -->
        <div class="answer-bg" style="margin: 15px 0">
          <div class="">
            <span>{{ $t('test.score') }}：</span>
            <span class="red">{{ scoreValue }}</span>
            <svg-icon icon-class="correct" class="correct-icon" style="color: #fb3636" @click="$emit('correctOper')" />
          </div>
          <div class="" style="padding-top: 15px">
            <span>{{ $t('test.standardAns') }}：</span>
          </div>
        </div>
        <div v-if="clickItem.answers">
          <mavon-editor :toolbars="toolbars" v-model="clickItem.answers" class="mavonEditor" :editable="!haveReadonly"
            :language="editorLanguage" />
        </div>
        <div v-if="!clickItem.answers" style="color: #f00">
          {{ $t('test.noStandardAns') }}
        </div>
      </div>
    </div>

    <div class="operation-box">
      <el-button class="last" @click="$emit('last')">{{
      $t('test.lastQuestion')
      }}</el-button>
      <el-button type="primary" class="next" @click="$emit('next')">{{
      $t('test.nextQuestion')
      }}</el-button>
    </div>
  </div>
</template>

<script>
import aceEditor from 'vue2-ace-editor'
import 'mavon-editor/dist/css/index.css'
import Cookies from 'js-cookie'
import { post } from '@/api/http'
export default {
  name: 'test',
  props: {
    clickItem: {
      type: Object,
      default() {
        return {}
      },
    },
    answerData: {
      type: Object,
      default() {
        return {}
      },
    },
    haveReadonly: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  components: {
    aceEditor,
  },
  data() {
    return {
      input1: '',
      searchlist: {},
      options: [],
      value: '',
      value1: '',
      activeContent: '',
      isShow: false,
      checked: '',
      content: '',
      toolbars: {
        bold: true, // 粗体
        italic: true, // 斜体
        header: true, // 标题
        underline: true, // 下划线
        strikethrough: true, // 中划线
        mark: true, // 标记
        superscript: true, // 上角标
        subscript: true, // 下角标
        quote: true, // 引用
        ol: true, // 有序列表
        ul: true, // 无序列表
        link: true, // 链接
        imagelink: false, // 图片链接
        code: true, // code
        table: true, // 表格
        fullscreen: true, // 全屏编辑
        readmodel: true, // 沉浸式阅读
        htmlcode: true, // 展示html源码
        help: false, // 帮助
        /* 1.3.5 */
        undo: true, // 上一步
        redo: true, // 下一步
        trash: true, // 清空
        save: false, // 保存（触发events中的save事件）
        /* 1.4.2 */
        navigation: true, // 导航目录
        /* 2.1.8 */
        alignleft: true, // 左对齐
        aligncenter: true, // 居中
        alignright: true, // 右对齐
        /* 2.2.1 */
        subfield: true, // 单双栏模式
        preview: true, // 预览
      },
      scoreValue: '',
      editorLanguage: Cookies.get('language'),
      aceOptions: {
        enableBasicAutocompletion: true, // 启用基本自动完成
        enableSnippets: true, // 启用代码段
        enableLiveAutocompletion: true, // 启用实时自动完成
        printMarginColumn: 30,
        displayIndentGuides: false, // 显示参考线
        enableEmmet: true, // 启用Emmet
        tabSize: 6, // 标签大小
        fontSize: 14, // 设置字号
        useWorker: true, // 使用辅助对象
        showPrintMargin: false, //去除编辑器里的竖线
        enableMultiselect: true, //     选中多处
        readOnly: false, // 是否只读
        showFoldWidgets: true, // 显示折叠部件
        fadeFoldWidgets: true, // 淡入折叠部件
        wrap: true, //换行
      },
      runFlag: false,
      runRedError: false,
      runningResult: '',

      runCOdeContent: '',
      runCodeAnswers: '',

      chioceAnswer:[],//一批改题目列表

      isFold:true,//运行结果是否折叠
    }
  },
  computed: {
    changeData() {
      const { answerData, clickItem } = this
      return {
        answerData,
        clickItem,
      }
    },
  },
  watch: {
    answerData: {
      handler(newValue, oldValue) {
        this.getScore(newValue)
        // console.log(newValue);
        if(this.clickItem.type==1){
          this.chioceAnswer = newValue.answer.split(',')
        }
      },
      deep: true,
      immediate: true,
    },
    clickItem: {
      handler(newValue, oldValue) {
        // console.log(newValue);
      },
      deep: true,
      immediate: true,
    },
    changeData: function (newV) {
      // console.log(newV);
      if(newV.clickItem.type==1){
          this.chioceAnswer = newV.answerData.answer.split(',')
      }
    },
  },
  mounted() {
    this.aceOptions.readOnly = this.haveReadonly
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    seeAnswer() {
      this.$emit('add', this.defaultData)
    },
    labelSet(label) {
      if (label) {
        return label.toString()
      } else {
        return ''
      }
    },
    // 批改单个试题
    getScore(value) {
      if (value) {
        post('/exam-correct/find-id', {
          sheetId: value.id,
        }).then((res) => {
          if (res.code == 1) {
            this.runCodeAnswers = res.data.answer 
            this.runningResult = res.data.runningResult
            if (res.data.answerScore != null) {
              this.scoreValue = res.data.answerScore
            } else {
              this.scoreValue = 0
              this.autoCorrect()
            }
          } else {
            this.scoreValue = 0
          }
        })
      } else {
        this.scoreValue = 0
      }
    },
    // 选择题自动批改
    autoCorrect() {
      if (this.clickItem.type == '1') {
        let answer = this.anagrams(this.answerData.answer)
        let answer2 = this.anagrams(this.clickItem.answers)
        if (answer == answer2) {
          this.$emit('correctAuto', this.clickItem.score)
        } else {
          this.$emit('correctAuto', '0')
        }
      }
    },
    // 答案排序
    anagrams (str) {
        var arr = str.split(',')
        arr.sort(function (s1, s2) {
            let x1 = s1.toUpperCase();
            let x2 = s2.toUpperCase();
            if (x1 < x2) {
                return -1;
            }
            if (x1 > x2) {
                return 1;
            }
            return 0;
        }); 
        return arr.toString()
    },
    // 代码运行
    runCode() {
      post('/exam-answer/answer-programming-run', {
        source: this.clickItem.answers,
      }).then((res) => {
        this.runFlag = true
        if (res.code == 1) {
          this.runRedError = false
          this.runCOdeContent = res.data
          //this.$message.success(res.msg)
        } else {
          this.runRedError = true
          this.runCOdeContent = res.message
          //this.$message.error(res.data)
        }
      })
    },
    foldUp(){
      this.isFold = !this.isFold
    },
    editorInit: function (editor) {
      require('brace/ext/language_tools') //language extension prerequsite...
      // 设置语言
      require('brace/mode/java')
      require('brace/snippets/java')
      // 设置主题 按需加载
      require('brace/theme/monokai')
    },


  },
}
</script>

<style scoped>
.answer-bg {
  background-color: #fff6f6;
  padding: 15px;
}

.detail {
  height: calc(100vh - 260px);
  min-width: 100%;
  overflow: auto;
}

.detail-header {
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #ebebf5;
  color: #80809d;
  font-size: 14px;
}

.title-box {
  line-height: 26px;
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 16px;
  color: #333;
}

.star {
  width: 120px;
  text-align: right;
}

.detail-meta p {
  margin: 15px 0;
}

.detail-tag .el-tag {
  border-radius: 50px;
}

/* answer */
.detail-content {
  padding: 20px;
}

.content-box {
  min-height: 250px;
  border-radius: 2px;
  margin-bottom: 20px;
  background-color: #f6f6f6;
  border: 1px dashed var(--el-border-color-base);
}

/* collapse*/

.el-collapse {
  border: none;
}

::v-deep div[role='tab'] {
  width: 150px;
}

::v-deep div[role='tab'] .el-collapse-item__header {
  border: none;
}

::v-deep div[role='tab'] .el-collapse-item__header .el-button {
  margin-bottom: 20px;
}

::v-deep div[role='tab'] .el-collapse-item__arrow {
  display: none;
}

::v-deep .el-collapse-item__wrap {
  border: none;
}

::v-deep .el-collapse-item__wrap .el-collapse-item__content {
  padding-bottom: 0;
}

::v-deep .el-collapse-item__wrap .content-box {
  margin-bottom: 0;
}

.el-divider {
  margin: 0;
  background-color: #ebebf5;
}

.answer-box {
  padding: 15px;
}

.title-option {
  background-color: #f2f3f7;
  color: #778ca2;
  font-weight: bold;
  padding: 0 15px;
}

.list-option {
  /*line-height: 70px;*/
  padding: 10px;
}

.detail-content>>>.makeEditor {}

.makeEditor {
  margin: 20px 0;
  min-height: 100px;
  height: auto !important;
}

.operation-box {
  padding: 15px;
}

.operation-box .el-button {
  width: 150px;
}

.correct-icon {
  margin-left: 5px;
  vertical-align: -4px;
  margin-right: 5px;
  color: #fb3636;
}

.wrong-tips {
  color: rgb(255, 0, 0);
  background-color: #fff6f9;
  line-height: 32px;
  padding-left: 10px;
}

.correct {
  color: #67c23a;
  background-color: #f0f9eb;
  line-height: 40px;
  padding-left: 10px;
  margin-right: 10px;
  display: inline-block;
  border-radius: 3px;
}

.left-content {
  width: 48%;
  float: left;
}

/* .arrow-down-icon {
  width: 22px;
  height: 22px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: -11px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  line-height: 22px;
  cursor: pointer;
} */

.arrow-up-icon {
  width: 22px;
  height: 22px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: -11px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  line-height: 25px;
  cursor: pointer;
}

.run-code-results {
  line-height: 60px;
  color: #fff;
  cursor: pointer;
}

.run-code-perform {
  float: right;
  margin-top: 10px;
}

.run-code-dialog {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 275px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  z-index: 10;
  box-sizing: border-box;
}

.run-boder-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.black {
  color: #000;
}

.red {
  color: red;
}

.run-code-box {
  width: 100%;
  height: 60px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #272822;
  border-top: 1px solid rgb(91, 184, 207);
  z-index: 10;
}

.run-code {
  position: absolute;
  left: 1%;
}

.run-code span {
  font-size: 12px;
  text-align: center;
  color: rgb(226, 130, 130);
}

.run-code-content {
  width: 100%;
  height: 215px;
  padding: 15px;
  overflow-y: auto;
}
</style>
<style>
.ace_gutter-cell:first-of-type {
  padding-top: 15px;
}

.ace_scroller {
  padding-top: 15px;
}
</style>
