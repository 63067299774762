<template>
  <div class="interview">
    <div class="sear">
      <el-form :inline="true" :model="searchlist" class="demo-form-inline" size="mini" label-suffix="：">
        <el-form-item :label="$t('test.testName')">
          <el-input :placeholder="$t('common.pleaseEnter') + $t('test.testName')" v-model="searchlist.title">
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('test.position')">
          <el-select v-model="searchlist.positionId" :placeholder="$t('common.pleaseSelect')">
            <el-option v-for="(item, index) in positionList" :key="index" :label="item.positionName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList" size="mini">{{
          $t('common.query')
          }}</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="resetForm" size="mini">{{
          $t('common.reset')
          }}</el-button>
        </el-form-item>
        <el-form-item style="float: right">
          <el-button type="primary" @click="addForm()" size="medium">{{
          $t('test.addTest')
          }}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table">
      <div class="table-title">
        <el-row>
          <el-col :span="16">{{ $t('test.testName') }}</el-col>
          <el-col :span="2">{{ $t('test.invitedNum') }}</el-col>
          <el-col :span="2">{{ $t('test.completedNum') }}</el-col>
          <el-col :span="4">{{ $t('common.operation') }}</el-col>
        </el-row>
      </div>
      <div class="table-content">
        <ul style="margin-top: 5px" v-if="tableData.length">
          <li v-for="item in tableData" :key="item.id">
            <el-row>
              <div @click="editClick(item, 'see')">
                <el-col :span="16">
                  <div class="li-title">
                    <!--<img src="@/assets/u15450.svg" alt="">-->
                    {{ item.title }}
                    <!--<span class="warning">待发布</span>-->
                  </div>
                  <div class="li-info">
                    <span>{{
                    $t('test.position') + '：' + positionItem(item.positionId)
                    }}</span>
                    <span>{{
                    $t('test.grade') + '：' + (item.passScore || 0 )+ $t('test.mark')
                    }}</span>
                    <span>{{
                    $t('test.time') +
                    '：' +
                    (item.fullTime || 0) +
                    ' ' +
                    $tc('test.min', item.fullTime)
                    }}</span>
                  </div>
                  <div class="li-time">
                    <span>{{
                    $t('test.ctime') + '：' + setTime(item.createTime)
                    }}</span>
                  </div>
                </el-col>
                <el-col :span="2" style="line-height: 120px">
                  {{ item.headCount }}</el-col>
                <el-col :span="2" style="line-height: 120px">
                  {{ item.joinCount }}</el-col>
              </div>

              <el-col :span="4" style="line-height: 120px">
                <el-tooltip class="item" effect="light" :content="$t('common.edit')" placement="top-start">
                  <el-button icon="el-icon-edit" circle @click="editClick(item, 'edit')"></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="light" :content="$t('test.personManage')" placement="top-start">
                  <el-button icon="el-icon-user-solid" circle @click="testUser(item)"></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="light" :content="$t('common.delete')" placement="top-start">
                  <el-button icon="el-icon-delete" circle @click="deleteClick(item)" :disabled="item.headCount != 0">
                  </el-button>
                </el-tooltip>
                <!--<el-tooltip class="item" effect="light" content="答题录屏录屏" placement="top-start">-->
                <!--<el-button icon="el-icon-video-play" circle @click="seeVideo(item,'download')"></el-button>-->
                <!--</el-tooltip>-->
                <!--<el-button>默认按钮</el-button>-->
                <!--<svg-icon icon-class="edit" class="arrow-right"/>-->
                <!--<el-button icon="el-icon-view" circle @click="editClick(item,'see')"></el-button>-->
              </el-col>
            </el-row>
          </li>
        </ul>
        <div style="
            text-align: center;
            font-size: 20px;
            margin: 20px;
            color: #778cb5;
          " v-else>
          {{ $t('common.nodata') }}
        </div>
        <el-pagination :page-size="5" layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCurrentChange" @size-change="handleSizeChange" :page-sizes="pageConfig.pageSizes"
          :total="pageConfig.total"></el-pagination>
      </div>
    </div>
    <!--新增-->
    <div v-if="addDialog">
      <el-dialog append-to-body :title="$t('test.testDetail')" :visible.sync="addDialog" :before-close="clearAdd"
        width="80%">
        <TestAdd ref="addRef" :rowData="rowData" :positionList="positionList" @add="saveAdd" @clear="clearAdd"
          :dialogType="dialogType">
        </TestAdd>
      </el-dialog>
    </div>
    <!--答题录屏播放-->
    <div v-if="videoDialog">
      <el-dialog append-to-body :title="$t('test.recordplay')" :visible.sync="videoDialog" :before-close="clearAdd"
        width="80%">
        <div class="video-box" v-if="videoSrc">
          <video :src="videoSrc" autoplay controls style="margin: 0 auto"></video>
        </div>
        <div class="boontbtn">
          <el-button type="info" size="small" @click="clearAdd">{{
          $t('common.close')
          }}</el-button>
        </div>
      </el-dialog>
    </div>
    <!--人员管理-->
    <div v-if="personnelDialog">
      <el-dialog append-to-body :title="$t('test.personManage')" :visible.sync="personnelDialog"
        :before-close="clearAdd" width="100%" top="68px">
        <TestPersonnel ref="testRef" :rowData="rowData" :positionList="positionList" @clear="clearAdd" :dialogType="dialogType"></TestPersonnel>
      </el-dialog>
    </div>
    <!--&lt;!&ndash;查看&ndash;&gt;-->
    <div v-if="seeDialog">
      <el-dialog append-to-body :title="$t('test.testDetail')" :visible.sync="seeDialog" :before-close="clearAdd"
        width="80%">
        <TestSee ref="addRef" :rowData="rowData" :positionList="positionList" @clear="clearAdd"
          :dialogType="dialogType"></TestSee>
      </el-dialog>
    </div>
    <!--&lt;!&ndash;面试结果&ndash;&gt;-->
    <!--<div v-if="resultDialog">-->
    <!--<el-dialog append-to-body :title="resultData.positionName+'面试'+resultData.candidateName" :visible.sync="resultDialog" width="50%">-->
    <!--<InterviewResult :resultData="resultData" @clear="resultDialog=false"></InterviewResult>-->
    <!--</el-dialog>-->
    <!--</div>-->
    <!--&lt;!&ndash;预览&ndash;&gt;-->
    <!--<div v-if="videoDialog">-->
    <!--<el-dialog append-to-body title="录屏预览" :visible.sync="videoDialog" width="50%" style="text-align: center;">-->
    <!--<video :src="videoSrc" autoplay controls></video>-->
    <!--</el-dialog>-->
    <!--</div>-->
  </div>
</template>

<script>
import { post, del, get, putUrl } from '@/api/http'
import TestAdd from './test-add'
import TestSee from './test-add'

import TestPersonnel from '../test-personnel/personnel-index'
// import InterviewSee from './interview-see'
// import InterviewResult from './interview-result'
import request from '@/utils/request.js'

export default {
  name: 'index',
  components: { TestAdd, TestPersonnel, TestSee },
  data() {
    return {
      // 分页配置
      pageConfig: {
        pageSize: this.$pageSize,
        total: 0,
        pageNo: 1,
        pageSizes: this.$pageSizes,
      },
      tableData: [],
      searchlist: {},
      addDialog: false,
      videoDialog: false,
      dialogType: 'add',
      loading: false,
      seeDialog: false,
      personnelDialog: false,
      rowData: {
        fullTime: '',
      },
      input1: '',
      value: '',
      value1: '',
      positionList: [],
      options: [],
      videoSrc: '',
    }
  },
  methods: {
    // 分页
    handleCurrentChange(val) {
      this.pageConfig.pageNo = val
      this.getList()
    },
    handleSizeChange(val) {
      this.pageConfig.pageSize = val
      this.getList()
    },
    getList() {
      post('/exam/list', {
        page: this.pageConfig.pageNo,
        limit: this.pageConfig.pageSize,
        ...this.searchlist,
      }).then((res) => {
        if (res.code == 1) {
          this.tableData = res.data.data
          this.pageConfig.total = res.data.count
        } else {
          this.$message.error(res.data)
        }
      })
    },
    resetForm() {
      this.searchlist = {}
      this.getList()
    },
    addForm() {
      this.rowData = {
        fullTime: '',
      }
      this.dialogType = 'add',
        this.addDialog = true
    },
    clearAdd() {
      this.addDialog = false
      this.seeDialog = false
      this.videoDialog = false
      this.personnelDialog = false
    },
    saveAdd(data) {
      post('/exam/insert', data).then((res) => {
        if (res.code == 1) {
          this.$message.success(this.$t('common.success'))
          this.clearAdd()
          this.getList()
        } else {
          this.$message.error(res.data)
        }
      })
    },
    positionItem(id) {
      let returnName = ''
      this.positionList.forEach((item) => {
        if (item.id == id) {
          returnName = item.positionName
        }
      })
      return returnName
    },
    getPosition() {
      post(
        '/dict-position/list',
        {
          page: 1,
          limit: 1000,
        },
        true
      ).then((res) => {
        if (res.code == 1) {
          this.positionList = res.data.data
        } else {
          this.$message.error(res.data)
        }
      })
    },
    editClick(row, type) {
      get('/exam/' + row.id).then((res) => {
        if (res.code == 1) {
          this.rowData = res.data
          this.dialogType = type
          if (type == 'edit') {
            this.addDialog = true
            this.$nextTick(() => {
              this.$refs['addRef'].paperChange()
            })
          } else {
            this.seeDialog = true
            this.$nextTick(() => {
              this.$refs['addRef'].paperChange()
            })
          }
        } else {
          this.$message.error(res.data)
        }
      })
    },
    deleteClick(row) {
      this.$confirm(this.$t('common.sureToDel'), this.$t('common.tips'), {
        confirmButtonText: this.$t('common.ok'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning',
      })
        .then(() => {
          del('/exam/delete/' + row.id).then((res) => {
            if (res.code == 1) {
              this.$message.success(this.$t('common.success'))
              this.getList()
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => { })
    },
    setTime(time) {
      let date = new Date(time)
      let y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
    },
    result(row) {
      get('/video-interview/result/' + row.id).then((res) => {
        if (res.code == 1) {
          this.resultData = res.data
          this.resultDialog = true
        } else {
          this.$message.error(res.data)
        }
      })
    },
    // 下载
    async download(row, type) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      var res = await request({
        url: '/exam/download',
        method: 'POST',
        params: {
          id: row.id,
        },
        responseType: 'blob',
      })
      const blob = new Blob([res], {
        type: 'video/mp4',
      })
      const fileName =
        this.$t('interview.answerRecordScreen') + '-' + row.candidateName
      const linkNode = document.createElement('a')

      linkNode.download = fileName //a标签的download属性规定下载文件的名称
      linkNode.style.display = 'none'
      linkNode.href = URL.createObjectURL(blob) //生成一个Blob URL
      this.videoSrc = URL.createObjectURL(blob)
      this.loading = false
      loading.close()
      this.videoDialog = true
    },
    seeVideo(row) {
      this.loading = true
      this.download(row)
    },
    testUser(item) {
      this.$router.push({
        name: 'TestPersonnel',
        query: { testData: JSON.stringify(item) },
      })
    },
  },
  mounted() {
    this.getList()
    this.getPosition()
  },
}
</script>

<style scoped>
.test {
  padding: 15px;
}

.left-card {
  width: 15%;
  float: left;
  height: 100vh;
  background-color: #fff;
}

.right-card {
  float: right;
  background-color: #fff;
  padding: 15px;
  width: calc(85% - 50px);
}

.table .table-title {
  background-color: #f3f5f7;
  padding: 10px 20px;
  font-size: 14px;
  color: #778ca2;
  font-weight: bold;
}

.table .table-content {}

.table-content li {
  min-height: 120px;
  padding: 15px 20px;
  border-bottom: 1px solid #f3f5f7;
}

.table-content li:hover {
  background-color: #f8f8fd;
}

.table-content li div {
  line-height: 40px;
}

.table-content li .li-title {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.table-content li .li-title img {
  margin-right: 10px;
}

.table-content li .li-title span {
  margin-left: 10px;
  font-size: 14px;
  color: #fff;
  padding: 0 15px;
  line-height: 22px;
}

.table-content li .li-info {
  padding-left: 30px;
}

.table-content li .li-info span {
  font-size: 14px;
  display: inline-block;
  width: 240px;
  color: #7b8b99;
}

.table-content li .li-time {
  padding-left: 30px;
}

.table-content li .li-time span {
  font-size: 14px;
  display: inline-block;
  color: #7b8b99;
}

.warning {
  background-color: #ffb61e;
}

.success {
  background-color: #00af9b;
}

.danger {
  background-color: #ff2121;
}

.video-box {
  text-align: center;
}
</style>
