<template>
    <div class="login">
        <div class="header">
            <div class="navbar">
                <el-row>
                    <el-col :span="4">
                        <!-- <div class="nav-img">
                            <img src="@/assets/login/logo2.png" alt="" style="height: 50px" />
                        </div> -->
                    </el-col>
                    <el-col :span="16"> </el-col>
                    <el-col :span="4"> </el-col>
                </el-row>
            </div>
        </div>
        <div class="content" v-loading="loadingInfo" :element-loading-text="$t('test.getInfo')">
            <div v-if="!loadingInfo">
                <div class="type-box" v-if="infoData.id&&infoData.status!==2">
                    <div class="type-title">
                        <h2>{{ infoData.title }}</h2>
                        <p>{{ $t('test.welcome') + $t('test.examination') }}</p>
                    </div>
                    <div class="remark">
                        <!--<p>{{infoData.remark}}</p>-->
                        <mavon-editor :value="infoData.remark" defaultOpen="preview" :boxShadow="false"
                            style="z-index: 1;width:406px;height: 262px;border-radius: 0; overflow: hidden;border: none;" 
                            :editable="false" :subfield="false" :toolbarsFlag="false"
                            class="makeEditor" :language="editorLanguage">
                        </mavon-editor>
                    </div>
                    <div class="get-into">
                        <el-button type="primary" @click="getInto" v-if="infoData">{{
                        $t('test.enterTest')
                        }}</el-button>
                    </div>
                    <!-- <div class="type-time">
                        <p>
                            {{ $t('test.testTime') }}：<span>{{ infoData.fullTime }}</span>
                            {{ $tc('test.min', infoData.fullTime) }}
                        </p>
                    </div> -->
                </div>
                <div class="type-box" v-else>
                    <h2 style="text-align: center">{{ $t('test.invalidAndContect') }}</h2>
                </div>
            </div>
        </div>
        <!--填写信息验证码-->
        <div v-if="infoDialog">
            <el-dialog center title="" :visible.sync="infoDialog" :before-close="clearInfo"
                width="532px" custom-class="info-dialog">
                <div class="login-box">
                    <div class="text-center">
                        <img src="../../assets/test/key.png" alt="" width="196px" height="110px">
                    </div>
                    <div class="login-info">
                        <h4>{{ $t('test.authenticate') }}</h4>
                        <el-form :inline="true" label-position="top" ref="infoForm" :model="form" :rules="rules"
                            label-width="85px" label-suffix=": ">
                            <el-form-item :label="''">
                                <el-input :value="infoData.candidateName" autocomplete="off" disabled >
                                    <template slot="prepend"><i class="el-icon-user"></i></template>
                                </el-input>
                            </el-form-item>
                            <el-form-item :label="''">
                                <el-input v-model="infoData.candidatePhone" autocomplete="off" disabled>
                                    <template slot="prepend"><i class="el-icon-mobile"></i></template>
                                </el-input>
                            </el-form-item>
                            <el-form-item :label="''">
                                <el-input v-model="infoData.candidateEmail" autocomplete="off" disabled>
                                    <template slot="prepend"><i class="el-icon-message"></i></template>
                                </el-input>
                            </el-form-item>
                            <div v-if="infoData.isVerification">
                                <el-form-item :label="''" prop="code"
                                v-if="infoData.notificationMode == 1">
                                <el-input :placeholder="$t('test.emailCode')" v-model="form.code"
                                class="input-with-select">
                                        <template slot="prepend"><i class="el-icon-lock"></i></template>
                                        <el-button slot="append" @click="openCode(infoData.notificationMode)"
                                            :disabled="codeDisabled">{{
                                            codeMsg }}</el-button>
                                    </el-input>
                                </el-form-item>
                                <el-form-item :label="$t('test.vCode')" prop="code" v-else>
                                    <el-input :placeholder="$t('test.verifyCode')" v-model="form.code"
                                        class="input-with-select">
                                        <el-button slot="append" @click="openCode(infoData.notificationMode)"
                                            :disabled="codeDisabled">{{
                                            codeMsg }}</el-button>
                                    </el-input>
                                </el-form-item>
                            </div>
                            <el-form-item v-if="infoData.screenRecording == 1" style="margin-bottom:0;margin-left:15px">
                                <el-checkbox v-model="isShare">{{
                                $t('test.openScreenShare')
                                }}</el-checkbox>
                            </el-form-item>
                            <el-form-item class="tip">
                                <span v-if="messageTip=='sms'">
                                {{$t('test.vCodeSent')}} 
                                <i>{{infoData.candidatePhone }}</i>
                                {{$t('test.sentPhone')}}</span>
                                <span v-else-if="messageTip=='email'">
                                {{$t('test.emailCodeSent')}}
                                <i>{{infoData.candidateEmail}}</i>
                                {{$t('test.Email')}}</span>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <div slot="footer" class="dialog-footer" style="text-align: center">
                    <el-button @click="clearInfo">
                        <i class="el-icon-close"></i>
                    </el-button>
                    <el-button type="primary" @click="submitCode">
                        {{$t('test.agreeToSubmit')}}
                    </el-button>
                </div>
            </el-dialog>
        </div>
        <!-- 用户填写信息 -->
        <div v-if="fillDialog">
            <el-dialog append-to-body center :title="$t('candidate.fillInformation')" :visible.sync="fillDialog"
                :before-close="fillClear" width="80%">
                <Fill @fillClear="fillClear" ref="fillRef" :fillData="fillData" @submitForm="submitForm"
                    @skipClick="skipClick">
                </Fill>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import Fill from './fill'
import '@/components/Meeting/aliyun-webrtc-sdk-1.12.1.js'
import 'mavon-editor/dist/css/index.css'
import { post, del, get, postJSON } from '@/api/http'
import Cookies from 'js-cookie'
import qs from 'qs'
export default {
    name: 'index',
    data() {
        return {
            aliWebrtc: new AliRtcEngine(),
            isInfo: true,
            infoDialog: false,
            infoData: {},
            form: {},
            rules: {
                interviewer: [
                    {
                        required: true,
                        message: this.$t('common.pleaseEnter') + this.$t('test.name') + '!',
                        trigger: 'blur',
                    },
                ],
                code: [
                    {
                        required: true,
                        message:
                            this.$t('common.pleaseEnter') + this.$t('test.verifyCode') + '!',
                        trigger: 'blur',
                    },
                ],
                channelId: [
                    {
                        required: true,
                        message:
                            this.$t('common.pleaseEnter') + this.$t('test.channel') + '!',
                        trigger: 'blur',
                    },
                ],
            },
            formLabelWidth: '140px',
            interviewer: '',
            hasVideo: false,
            hasAudio: false,
            loginInfo: {},
            isShare: false,
            codeMsg: this.$t('test.getVerifyCode'),
            codeDisabled: false,
            loadingInfo: true,
            editorLanguage: Cookies.get('language'),
            fillDialog: false,
            fillData: {},
            messageTip: '',
        }
    },
    components: {
        Fill,
    },
    methods: {
        getInto() {
            this.infoDialog = true
            // post('/exam-examinee/getExamExamineeEntityDetails', {
            //   id: this.infoData.id,
            // }).then((res) => {
            //   if (res.code == 1) {
            //     this.fillData = res.data
            //     this.fillDialog = true
            //     this.infoData.screenRecording = res.data.screenRecording
            //   } else {
            //     this.$message.error(res.data)
            //   }
            // })
            // localStorage.setItem('info', JSON.stringify(this.infoData));
            // this.$router.push({name:'AnswerHome',params:{info:this.loginInfo}});
        },
        clearInfo() {
            this.infoDialog = false
            // this.messageTip = ''
        },
        //获取笔试信息
        getInfo() {
            let id = this.$route.query.id
            if (id) {
                get('/exam-answer/play/' + id).then((res) => {
                    this.loadingInfo = false
                    //   console.log(this.loadingInfo);
                    if (res.code == 1) {
                        this.isInfo = false
                        this.infoData = res.data
                        console.log(this.infoData)
                        if (res.data.candidatePhonePrefix == '86') {
                            this.$i18n.locale = 'zh-Cn'
                        } else {
                            this.$i18n.locale = 'en'
                        }
                        this.$store.dispatch('app/setLanguage', this.$i18n.locale)
                    } else {
                        this.isInfo = true
                        // this.$message.error(res.data)
                    }
                })
            } else {
                this.isInfo = true
                this.$message.error(this.$t('test.invalidAndContect'))
            }
        },
        //提交验证码，通过跳转笔试页面
        submitCode() {
            let url = ''
            let obj = {
                smsCode: this.form.code,
            }
            if (this.infoData.notificationMode == 1) {
                url = '/exam-answer/check-mail-code'
                obj.mail = this.infoData.candidateEmail
            } else {
                url = '/exam-answer/check-sms-code'
                obj.phone = this.infoData.candidatePhone
            }
            this.$refs.infoForm.validate((valid) => {
                if (valid) {
                    if (this.infoData.candidatePhone) {
                        // 验证
                        if (
                            (this.isShare && this.infoData.screenRecording == 1) ||
                            (!this.isShare && this.infoData.screenRecording == 2)) {
                            if (this.infoData.isVerification) {
                                post(url, obj).then((res) => {
                                    if (res.code == 1) {
                                        this.skipClick()
                                        // post('/exam-examinee/getExamExamineeEntityDetails', {
                                        //     id: this.infoData.id,
                                        // }).then((resq) => {
                                        //     if (resq.code == 1) {
                                        //         this.fillData = resq.data
                                        //         this.infoData.screenRecording = resq.data.screenRecording
                                        //         this.fillDialog = true
                                        //         localStorage.setItem(
                                        //             'info',
                                        //             JSON.stringify(this.infoData)
                                        //         )
                                        //     } else {
                                        //         this.$message.error(resq.data)
                                        //     }
                                        // })
                                        //this.$message.success(this.$t('test.joinSuccess'))
                                    } else {
                                        if (res.data == '验证码过期') {
                                            this.$message.error(this.$t('test.vCodeInvalid'))
                                        } else {
                                            this.$message.error(res.data)
                                        }
                                    }
                                })
                                } else {
                                    this.skipClick()
                                // 不验证
                                // post('/exam-examinee/getExamExamineeEntityDetails', {
                                //     id: this.infoData.id,
                                // }).then((resq) => {
                                //     if (resq.code == 1) {
                                //         this.fillData = resq.data
                                //         this.infoData.screenRecording = resq.data.screenRecording
                                //         this.fillDialog = true
                                //         localStorage.setItem(
                                //             'info',
                                //             JSON.stringify(this.infoData)
                                //         )
                                //     } else {
                                //         this.$message.error(resq.data)
                                //     }
                                // })
                            }
                        } else {
                            this.$message({
                                message: this.$t('test.agreeToRecord'),
                                type: 'warning',
                            })
                        }
                    } else {
                        this.$message.error(
                            this.$t('common.pleaseEnter') + this.$t('test.phone')
                        )
                    }
                } else {
                    this.$message.error(
                        this.$t('common.pleaseEnter') + this.$t('test.verifyCode')
                    )
                }
            })
        },
        //打开验证码信息
        openCode(type) {
            if (this.infoData.candidatePhone) {
                let url = ''
                let obj = {}
                if (type == 1) {
                    url = '/exam-answer/send-mail-code'
                    obj.mail = this.infoData.candidateEmail
                } else {
                    url = '/exam-answer/send-sms-code'
                    obj.phone = this.infoData.candidatePhone
                    obj.prefix = this.infoData.candidatePhonePrefix
                }
                post(url, obj).then((res) => {
                    if (res.code == 1) {
                        if (res.data.substr(0, 6) == '验证码已发送' && this.infoData.notificationMode == 2) {
                            this.messageTip = 'sms'
                        } else if (res.data.substr(0, 6) == '验证码已发送' && this.infoData.notificationMode == 1) {
                            this.messageTip = 'email'
                        } else {
                            this.$message.success(res.data)
                        }
                        this.codeMsg = 61
                        this.codeDisabled = true
                        this.countTime()
                    } else {
                        this.$message.error(res.data)
                    }
                })
            } else {
                this.$message.error(
                    this.$t('common.pleaseEnter') + this.$t('test.phone')
                )
            }
        },
        // 倒计时
        countTime() {
            this.codeMsg = Number(this.codeMsg) - 1
            // 等于0的时候不调用
            if (Number(this.codeMsg) === 0) {
                this.codeMsg = this.$t('test.getVerifyCode')
                this.codeDisabled = false
                return
            } else {
                // 递归每秒调用countTime方法，显示动态时间效果,
                setTimeout(this.countTime, 1000)
            }
        },
        //阿里云检测设备信息
        testing() {
            //阿里云检测设备信息
            this.aliWebrtc
                .getDevices()
                .then((re) => {
                    if (re.videoDevices.length) {
                        this.hasVideo = false
                    } else {
                        this.hasVideo = true
                    }
                    if (re.audioDevices.length) {
                        this.hasAudio = false
                    } else {
                        this.hasAudio = true
                    }
                    if (this.hasAudio || this.hasVideo) {
                        alert(this.$t('test.noCamaraOrMic'))
                    }
                })
                .catch((error) => {
                    console.log(error.message)
                })
        },
        nameSet(name) {
            return name.split('_')[1]
        },

        //测试用户填写
        fillClear() {
            this.fillDialog = false
        },
        async skipClick() {
            // let obj = {
            //   phone: this.infoData.candidatePhone,
            //   smsCode: this.form.code,
            // }
            // post('/exam-answer/check-sms-code', obj).then((res) => {
            //   if (res.code == 1) {
            //     localStorage.setItem('info', JSON.stringify(this.infoData))

            //     // this.$router.push({name:'Home',params:{info:this.infoData}})
            //     this.$message.success(this.$t('test.joinSuccess'))
            //   } else {
            //     if (res.data == '验证码过期') {
            //       this.$message.error(this.$t('test.vCodeInvalid'))
            //     } else {
            //       this.$message.error(res.data)
            //     }
            //   }
            // })
            await this.updateTimeStamp()
            localStorage.setItem('info', JSON.stringify(this.infoData))
            this.$router.push({
                name: 'AnswerHome',
                params: { info: this.loginInfo },
                query: {
                    id: this.$route.query.id,
                },
            })
            console.log(this.$route.query.id)
            // this.$message.success(this.$t('test.joinSuccess'))
        },
        async submitForm(item) {
            //console.log(item)
            await this.updateTimeStamp()
            var objs = { id: this.infoData.id, ...item }
            postJSON('/exam-examinee/updateExamExamineeEntity', objs).then((res) => {
                if (res.code == 1) {
                    localStorage.setItem('info', JSON.stringify(this.infoData))
                    this.fillDialog = false
                    this.$router.push({
                        name: 'AnswerHome',
                        params: { info: this.loginInfo },
                        id: this.$route.query.id,
                    })
                    // this.$message.success(this.$t('test.joinSuccess'))
                } else {
                    this.$message.error(res.data)
                }
            })
        },
        // 记录考试开始时间戳
        updateTimeStamp(){
            return new Promise((resolve, reject)=>{
                post('/exam-examinee/updateExamineeTimeStartExam', {
                    id:this.infoData.id
                }).then((res) => {
                    resolve(res.code)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
    mounted() {
        this.getInfo()
        // this.testing();
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            if (from.name == 'AnswerHome') {
                location.reload()
            }
        })
    },
}
</script>

<style scoped>
.text-center{
    text-align: center;
}
.navbar {
    width: 100%;
    height: 68px;
    background: transparent;
    position: absolute;
}

.nav-img {
    text-align: left;
    padding: 40px;
    display: flex;
    align-items: center;
    height: 68px;
}

.nav-menu {
    height: 68px;
}

.nav-my {
    height: 68px;
}

.content {
    width: 100%;
    height: 100vh;
    /* height: calc(100vh - 68px); */
    background-color: #ececf4;
    background: url(../../assets/test/welcome.png);
    padding: 1px 0 0;
    box-sizing: border-box;
    background-size: cover;
}

.type-box {
    /* width: 24%;
    height: 600px;
    background-color: #fff;*/
    padding: 60px 62px 50px; 
    margin: 8% auto 0;
    position: relative;
    box-sizing: border-box;
    width: 530px;
    height: 570px;
    background: #111111;
    border-radius: 20px;
    color: #fff;
}

.type-box .type-title,.type-box .type-time {
    /* text-align: center; */
    color:#ffffff
}
.type-box .type-title h2{
    font-weight: bold;
    font-size: 18px;
}

.type-box .type-title p {
    font-size: 18px;
    margin: 20px 0;
}
.type-box .type-time p {
    font-size: 16px;
    margin: 15px;
    color: #999;
    line-height: 40px;
}
.type-box .type-time span {
    color: #F4433E;
}

.type {
    width: 350px;
    height: 250px;
    border: 1px solid #d5d5e6;
    position: absolute;
    padding: 20px;
}

.type-left {
    left: 15%;
    top: 140px;
}

.type-right {
    right: 15%;
    top: 140px;
}

.tt-content {
    text-align: center;
    margin-top: 80px;
}

.tt-content p {
    margin-bottom: 40px;
}
/* 验证 */
.login-box {
    width: 322px;
    position: relative;
    margin-top:20px;
    padding:0 75px
}
.login-info {
    width: 100%;
}
.login-info h4{
    font-size: 22px;
    font-weight: bold;
    margin: 45px 0;
    color:#ffffff
}

.login-info .el-form-item,.input-with-select{
    width: 100%
}
::v-deep .info-dialog .el-form-item:not(.tip){
    border:1px solid #ffffff;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 25px;
}
::v-deep .info-dialog .el-form-item:last-child{
    margin-bottom: 0;
}
::v-deep .info-dialog input::-webkit-input-placeholder{
    color: #787878;
}
::v-deep .info-dialog input::-moz-placeholder{
    color: #787878;
    opacity: 1;
}
::v-deep .info-dialog .el-input.is-disabled .el-input__inner,::v-deep .info-dialog .el-input .el-input__inner{
    background-color: #111111;
    border:0;
    border-radius: 0;
    font-size: 16px;
    height: 60px;
}
::v-deep .el-input-group__prepend{
    background-color: #111111;
    padding: 0 10px;
    font-size: 24px;
    border:0;
    border-radius: 0;
    color:#333;
}
::v-deep .el-input-group__append{
    background-color: #111111;
    padding: 0 10px;
    font-size: 16px;
    border:0;
    border-radius: 0;
    color:#F56D6A;
}


.remark {
    width:100%;
    min-height: 262px;
    margin: 45px auto 0;
    overflow: auto;
}

.get-into {
    text-align: center;
}
.get-into .el-button{
    width: 340px;
    height: 54px;
    border-radius: 15px;
    font-size: 18px;
    background:#085CF8;
    border: 0;
    margin:0 auto;
}

/* checkbox换行 */
::v-deep .el-checkbox__label {
    display: inline-grid;
    white-space: pre-line;
    /* word-wrap: break-word; */
    overflow: hidden;
    line-height: 20px;
}
.tip{
    margin-top: 25px;
}
.tip span {
    background: #382710;
    padding: 0 15px;
    color: #F29A34;
    display: inline-block;
    line-height: 62px;
    width: 100%;
    box-sizing: border-box;
}
.tip span i{
    color: #FF9000 ;
    font-size: 12px;
    font-style: normal;
}

/* 对话框 */
::v-deep  .info-dialog.el-dialog{
    background: #111111;
    border-radius: 15px;
}
::v-deep  .info-dialog .el-dialog__header{
    display: none;
}
::v-deep  .info-dialog .el-dialog__body{
    padding-bottom: 0;
}
::v-deep  .info-dialog .el-dialog__footer{
    padding:0 20px 55px
}
.dialog-footer .el-button{
    height: 54px;
    width: 324px;
    border-radius: 15px;
    color: #fff;
    border:none;
}
/* .dialog-footer .el-button:first-child{
    margin-right: 40px;
} */
.dialog-footer .el-button i{
    font-weight: bold;
    font-size: 24px;
}
.dialog-footer .el-button.el-button--default {
    /* background:linear-gradient(to right, #A7A7A7 , #5D5D5D) ; */
    display: none;
}
.dialog-footer .el-button.el-button--primary {
    background:#085CF8;
    margin-left: -9px;
}
/* 编辑器 */
::v-deep .makeEditor .v-note-wrapper{
    border:0
}
::v-deep .makeEditor .v-show-content{
    background-color:#111111 !important;
    padding:0 !important;
    color: #787878;
    font-size: 14px;
    line-height: 30px;
}
</style>


